import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../../router'

export default function usePurchaseRequestsList() {
  const toast = useToast()
  const refPurchaseRequestListTableAssetsFGS = ref(null)
  const refPurchaseRequestListTableSupplies = ref(null)
  const refPurchaseRequestListTableHO = ref(null)
  const refPurchaseRequestListTableSuppliesNA = ref(null)
  const refPurchaseRequestListTableAssetsFGSNA = ref(null)
  const refPurchaseRequestListTableHONA = ref(null)
  const refPurchaseRequestListTableFinishedAssetsFGS = ref(null)
  const refPurchaseRequestListTableFinishedSupplies = ref(null)
  const refPurchaseRequestListTableFinishedHO = ref(null)
  const refPurchaseRequestListTableVoid = ref(null)
  const tableColumnsFinishedAssetsFGS = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinishedSupplies = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinishedHO = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsVoid = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'VoidTime', sortable: false },
    { key: 'actions' },
  ]
  const tableColumnsAssetsFGS = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsAssetsFGSNA = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsSupplies = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsSuppliesNA = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsHO = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsHONA = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinished = [
    { key: 'Number', sortable: true },
    { key: 'Class', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'CostCenter', sortable: true },
    { key: 'Notes', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const perPageAssetsFGS = ref(10)
  const perPageAssetsFGSNA = ref(10)
  const perPageFinishedAssetsFGS = ref(10)
  const perPageFinishedAssetsFGSNA = ref(10)
  const totalPurchaseRequestsAssetsFGS = ref(0)
  const totalPurchaseRequestsAssetsFGSNA = ref(0)
  const totalPurchaseRequestsFinishedAssetsFGS = ref(0)
  const currentPageAssetsFGS = ref(1)
  const currentPageAssetsFGSNA = ref(1)
  const currentPageFinishedAssetsFGS = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQueryAssetsFGS = ref('')
  const searchQueryAssetsFGSNA = ref('')
  const searchQueryFinishedAssetsFGS = ref('')
  const sortByAssetsFGS = ref('id')
  const sortByAssetsFGSNA = ref('id')
  const isSortDirDescAssetsFGS = ref(true)
  const startDateFilterAssetsFGS = ref('')
  const endDateFilterAssetsFGS = ref('')
  const isSortDirDescAssetsFGSNA = ref(true)
  const startDateFilterAssetsFGSNA = ref('')
  const endDateFilterAssetsFGSNA = ref('')
  const startDateFilterFinishedAssetsFGS = ref('')
  const endDateFilterFinishedAssetsFGS = ref('')
  const perPageSupplies = ref(10)
  const perPageSuppliesNA = ref(10)
  const perPageFinishedSupplies = ref(10)
  const totalPurchaseRequestsSupplies = ref(0)
  const totalPurchaseRequestsSuppliesNA = ref(0)
  const totalPurchaseRequestsFinishedSupplies = ref(0)
  const currentPageSupplies = ref(1)
  const currentPageSuppliesNA = ref(1)
  const currentPageFinishedSupplies = ref(1)
  const searchQuerySupplies = ref('')
  const searchQuerySuppliesNA = ref('')
  const searchQueryFinishedSupplies = ref('')
  const sortBySupplies = ref('id')
  const sortBySuppliesNA = ref('id')
  const isSortDirDescSupplies = ref(true)
  const startDateFilterSupplies = ref('')
  const endDateFilterSupplies = ref('')
  const startDateFilterSuppliesNA = ref('')
  const endDateFilterSuppliesNA = ref('')
  const isSortDirDescSuppliesNA = ref(true)
  const startDateFilterFinishedSupplies = ref('')
  const endDateFilterFinishedSupplies = ref('')
  const perPageHO = ref(10)
  const perPageHONA = ref(10)
  const totalPurchaseRequestsHO = ref(0)
  const currentPageHO = ref(1)
  const currentPageHONA = ref(1)
  const totalPurchaseRequestsHONA = ref(0)
  const searchQueryHO = ref('')
  const searchQueryHONA = ref('')
  const sortByHO = ref('id')
  const sortByHONA = ref('id')
  const isSortDirDescHO = ref(true)
  const startDateFilterHO = ref('')
  const endDateFilterHO = ref('')
  const isSortDirDescHONA = ref(true)
  const startDateFilterHONA = ref('')
  const endDateFilterHONA = ref('')
  const perPageFinishedHO = ref(10)
  const totalPurchaseRequestsFinishedHO = ref(0)
  const currentPageFinishedHO = ref(1)
  const searchQueryFinishedHO = ref('')
  const startDateFilterFinishedHO = ref('')
  const endDateFilterFinishedHO = ref('')
  const perPageVoid = ref(10)
  const totalPurchaseRequestsVoid = ref(0)
  const currentPageVoid = ref(1)
  const searchQueryVoid = ref('')
  const startDateFilterVoid = ref('')
  const endDateFilterVoid = ref('')

  const dataMeta = computed(() => {
    const localItemsCountAssetsFGS = refPurchaseRequestListTableAssetsFGS.value ? refPurchaseRequestListTableAssetsFGS.value.localItems.length : 0
    const localItemsCountAssetsFGSNA = refPurchaseRequestListTableAssetsFGSNA.value ? refPurchaseRequestListTableAssetsFGSNA.value.localItems.length : 0
    const localItemsCountSupplies = refPurchaseRequestListTableSupplies.value ? refPurchaseRequestListTableSupplies.value.localItems.length : 0
    const localItemsCountSuppliesNA = refPurchaseRequestListTableSuppliesNA.value ? refPurchaseRequestListTableSuppliesNA.value.localItems.length : 0
    const localItemsCountHO = refPurchaseRequestListTableHO.value ? refPurchaseRequestListTableHO.value.localItems.length : 0
    const localItemsCountHONA = refPurchaseRequestListTableHONA.value ? refPurchaseRequestListTableHONA.value.localItems.length : 0
    const localItemsCountVoid = refPurchaseRequestListTableVoid.value ? refPurchaseRequestListTableVoid.value.localItems.length : 0
    return {
      fromAssetsFGS: perPageAssetsFGS.value * (currentPageAssetsFGS.value - 1) + (localItemsCountAssetsFGS ? 1 : 0),
      toAssetsFGS: perPageAssetsFGS.value * (currentPageAssetsFGS.value - 1) + localItemsCountAssetsFGS,
      ofAssetsFGS: totalPurchaseRequestsAssetsFGS.value,
      fromAssetsFGSNA: perPageAssetsFGSNA.value * (currentPageAssetsFGSNA.value - 1) + (localItemsCountAssetsFGSNA ? 1 : 0),
      toAssetsFGSNA: perPageAssetsFGSNA.value * (currentPageAssetsFGSNA.value - 1) + localItemsCountAssetsFGSNA,
      ofAssetsFGSNA: totalPurchaseRequestsAssetsFGSNA.value,
      fromSupplies: perPageSupplies.value * (currentPageSupplies.value - 1) + (localItemsCountSupplies ? 1 : 0),
      toSupplies: perPageSupplies.value * (currentPageSupplies.value - 1) + localItemsCountSupplies,
      ofSupplies: totalPurchaseRequestsSupplies.value,
      fromSuppliesNA: perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1) + (localItemsCountSuppliesNA ? 1 : 0),
      toSuppliesNA: perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1) + localItemsCountSuppliesNA,
      ofSuppliesNA: totalPurchaseRequestsSuppliesNA.value,
      fromHO: perPageHO.value * (currentPageHO.value - 1) + (localItemsCountHO ? 1 : 0),
      toHO: perPageHO.value * (currentPageHO.value - 1) + localItemsCountHO,
      ofHO: totalPurchaseRequestsHO.value,
      fromVoid: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCountVoid ? 1 : 0),
      toVoid: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCountVoid,
      ofVoid: totalPurchaseRequestsVoid.value,
      fromHONA: perPageHONA.value * (currentPageHONA.value - 1) + (localItemsCountHONA ? 1 : 0),
      toHONA: perPageHONA.value * (currentPageHONA.value - 1) + localItemsCountHONA,
      ofHONA: totalPurchaseRequestsHONA.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCountFinishedAssetsFGS = refPurchaseRequestListTableFinishedAssetsFGS.value ? refPurchaseRequestListTableFinishedAssetsFGS.value.localItems.length : 0
    const localItemsCountFinishedSupplies = refPurchaseRequestListTableFinishedSupplies.value ? refPurchaseRequestListTableFinishedSupplies.value.localItems.length : 0
    const localItemsCountFinishedHO = refPurchaseRequestListTableFinishedHO.value ? refPurchaseRequestListTableFinishedHO.value.localItems.length : 0
    const localItemsCountVoid = refPurchaseRequestListTableVoid.value ? refPurchaseRequestListTableVoid.value.localItems.length : 0
    return {
      fromAssetsFGS: perPageFinishedAssetsFGS.value * (currentPageFinishedAssetsFGS.value - 1) + (localItemsCountFinishedAssetsFGS ? 1 : 0),
      toAssetsFGS: perPageFinishedAssetsFGS.value * (currentPageFinishedAssetsFGS.value - 1) + localItemsCountFinishedAssetsFGS,
      ofAssetsFGS: totalPurchaseRequestsFinishedAssetsFGS.value,
      fromSupplies: perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1) + (localItemsCountFinishedSupplies ? 1 : 0),
      toSupplies: perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1) + localItemsCountFinishedSupplies,
      ofSupplies: totalPurchaseRequestsFinishedSupplies.value,
      fromHO: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + (localItemsCountFinishedHO ? 1 : 0),
      toHO: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + localItemsCountFinishedHO,
      ofHO: totalPurchaseRequestsFinishedHO.value,
      fromVoid: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCountVoid ? 1 : 0),
      toVoid: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCountVoid,
      ofVoid: totalPurchaseRequestsVoid.value,
    }
  })

  const refetchDataAssetsFGS = () => {
    refPurchaseRequestListTableAssetsFGS.value.refresh()
  }
  const refetchDataAssetsFGSNA = () => {
    refPurchaseRequestListTableAssetsFGSNA.value.refresh()
  }
  const refetchDataFinishedAssetsFGS = () => {
    refPurchaseRequestListTableFinishedAssetsFGS.value.refresh()
  }
  const refetchDataSupplies = () => {
    refPurchaseRequestListTableSupplies.value.refresh()
  }
  const refetchDataSuppliesNA = () => {
    refPurchaseRequestListTableSuppliesNA.value.refresh()
  }
  const refetchDataFinishedSupplies = () => {
    refPurchaseRequestListTableFinishedSupplies.value.refresh()
  }
  const refetchDataHO = () => {
      refPurchaseRequestListTableHO.value.refresh()
  }
  const refetchDataHONA = () => {
      refPurchaseRequestListTableHONA.value.refresh()
  }
  const refetchDataFinishedHO = () => {
    refPurchaseRequestListTableFinishedHO.value.refresh()
  }
  const refetchDataVoid = () => {
    refPurchaseRequestListTableVoid.value.refresh()
  }
  watch([
    currentPageAssetsFGS,
    perPageAssetsFGS,
    searchQueryAssetsFGS,
    sortByAssetsFGS,
    sortByAssetsFGSNA,
    isSortDirDescAssetsFGS,
    currentPageFinishedAssetsFGS,
    perPageFinishedAssetsFGS,
    searchQueryFinishedAssetsFGS,
    startDateFilterAssetsFGS,
    endDateFilterAssetsFGS,
    startDateFilterFinishedAssetsFGS,
    endDateFilterFinishedAssetsFGS,
    currentPageSupplies,
    perPageSupplies,
    searchQuerySupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    currentPageFinishedSupplies,
    perPageFinishedSupplies,
    searchQueryFinishedSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    currentPageHO,
    perPageHO,
    searchQueryHO,
    sortByHO,
    isSortDirDescHO,
    currentPageFinishedHO,
    perPageFinishedHO,
    searchQueryFinishedHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    currentPageAssetsFGSNA,
    searchQueryAssetsFGSNA,
    startDateFilterAssetsFGSNA,
    endDateFilterAssetsFGSNA,
    currentPageSuppliesNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    currentPageHONA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    currentPageVoid,
    perPageVoid,
    searchQueryVoid,
    startDateFilterVoid,
    endDateFilterVoid,
  ], (newValues, oldValues) => {
    // Find the index of the changed property
    const changedIndex = newValues.findIndex((value, index) => value !== oldValues[index])

    // Perform actions based on the changed property
    if (changedIndex !== -1) {
      switch (changedIndex) {
        case 0: // currentPageAssetsFGS
          refetchDataAssetsFGS()
          break
        case 1: // currentPageAssetsFGS
          refetchDataAssetsFGS()
          break
        case 2: // currentPageAssetsFGS
          refetchDataAssetsFGS()
          break
        case 3: // currentPageAssetsFGS
          refetchDataAssetsFGS()
          break
        case 4: // currentPageAssetsFGS
          refetchDataAssetsFGS()
          break
        case 5: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 6: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 7: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 8: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 9: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 10: // refetchDataFinishedAssetsFGS
        refetchDataFinishedAssetsFGS()
          break
        case 11: // refetchDataSupplies
        refetchDataFinishedAssetsFGS()
          break
        case 12: // refetchDataSupplies
        refetchDataSupplies()
          break
        case 13: // refetchDataSupplies
        refetchDataSupplies()
          break
        case 14: // refetchDataSupplies
        refetchDataSupplies()
          break
        case 15: // refetchDataSupplies
          refetchDataSupplies()
          break
        case 16: // refetchDataSupplies
          refetchDataSupplies()
          break
        case 17: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 18: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 19: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 20: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 21: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 22: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 23: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 24: // refetchDataHO
        refetchDataHO()
          break
        case 25: // refetchDataHO
        refetchDataHO()
          break
        case 26: // refetchDataHO
        refetchDataHO()
          break
        case 27: // refetchDataHO
        refetchDataHO()
          break
        case 28: // refetchDataHO
        refetchDataHO()
          break
        case 29: // refetchDataFinishedHO
        refetchDataFinishedHO()
          break
        case 30: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 31: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 32: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 33: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 34: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 35: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 36: // refetchDataFinishedHO
          refetchDataAssetsFGSNA()
          break
          case 37: // refetchDataFinishedHO
            refetchDataAssetsFGSNA()
            break
          case 38: // refetchDataFinishedHO
            refetchDataAssetsFGSNA()
            break
          case 39: // refetchDataFinishedHO
            refetchDataAssetsFGSNA()
            break
          case 40: // refetchDataSuppliesNA
          refetchDataSuppliesNA()
            break
            case 41: // refetchDataSuppliesNA
            refetchDataSuppliesNA()
              break
              case 42: // refetchDataSuppliesNA
              refetchDataSuppliesNA()
                break
                case 43: // refetchDataSuppliesNA
                refetchDataSuppliesNA()
                  break
                  case 44: // refetchDataSuppliesNA
                  refetchDataSuppliesNA()
                    break
                    case 45: // refetchDataHONA
                    refetchDataHONA()
                      break
                      case 46: // refetchDataHONA
                      refetchDataHONA()
                        break
                        case 47: // refetchDataHONA
                        refetchDataHONA()
                          break
                          case 48: // refetchDataHONA
                          refetchDataHONA()
                            break
                            case 49: // refetchDataHONA
                            refetchDataHONA()
                              break
                              case 50: // refetchDataHONA
                              refetchDataHONA()
                                break
                                case 51: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 52: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 53: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 54: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 55: // refetchDataVoid
                                refetchDataVoid()
                                break
// Add cases for other properties if needed
        default:
          // Default action if none of the specified properties changed
          break
      }
    }
  })
  // () => {
  //   refetchDataAssetsFGS()
  //   refetchDataFinishedAssetsFGS()
  //   refetchDataSupplies()
  //   refetchDataFinishedSupplies()
  //   refetchDataHO()
  //   refetchDataFinishedHO()
  // })

  const fetchPurchaseRequestsAssetsFGS = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageAssetsFGS.value * (currentPageAssetsFGS.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageAssetsFGS.value}&offset=${to}&date_start=${startDateFilterAssetsFGS.value}&date_end=${endDateFilterAssetsFGS.value}&search=${searchQueryAssetsFGS.value}&category=Assets&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsAssetsFGS.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsAssetsFGSNA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageAssetsFGSNA.value * (currentPageAssetsFGSNA.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageAssetsFGSNA.value}&offset=${to}&date_start=${startDateFilterAssetsFGSNA.value}&date_end=${endDateFilterAssetsFGSNA.value}&search=${searchQueryAssetsFGSNA.value}&category=Assets&needs_approval=true&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsAssetsFGSNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsFinishedAssetsFGS = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedAssetsFGS.value * (currentPageFinishedAssetsFGS.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageFinishedAssetsFGS.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedAssetsFGS.value}&date_end=${endDateFilterFinishedAssetsFGS.value}&search=${searchQueryFinishedAssetsFGS.value}&category=Assets&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsFinishedAssetsFGS.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseRequestsSupplies = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageSupplies.value * (currentPageSupplies.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageSupplies.value}&offset=${to}&date_start=${startDateFilterSupplies.value}&date_end=${endDateFilterSupplies.value}&search=${searchQuerySupplies.value}&category=Supplies&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsSupplies.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsSuppliesNA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageSuppliesNA.value}&offset=${to}&date_start=${startDateFilterSuppliesNA.value}&date_end=${endDateFilterSuppliesNA.value}&search=${searchQuerySuppliesNA.value}&category=Supplies&needs_approval=true&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsSuppliesNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsFinishedSupplies = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageFinishedSupplies.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedSupplies.value}&date_end=${endDateFilterFinishedSupplies.value}&search=${searchQueryFinishedSupplies.value}&category=Supplies&type=local`, { headers })
      .then(response => {
        totalPurchaseRequestsSupplies.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHO.value * (currentPageHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageHO.value}&offset=${to}&date_start=${startDateFilterHO.value}&date_end=${endDateFilterHO.value}&search=${searchQueryHO.value}&type=center`, { headers })
      .then(response => {
        totalPurchaseRequestsHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsHONA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHONA.value * (currentPageHONA.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageHONA.value}&offset=${to}&date_start=${startDateFilterHONA.value}&date_end=${endDateFilterHONA.value}&search=${searchQueryHONA.value}&type=center&needs_approval=true`, { headers })
      .then(response => {
        totalPurchaseRequestsHONA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsFinishedHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedHO.value * (currentPageFinishedHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageFinishedHO.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedHO.value}&date_end=${endDateFilterFinishedHO.value}&search=${searchQueryFinishedHO.value}&type=center`, { headers })
      .then(response => {
        totalPurchaseRequestsFinishedHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseRequestsVoid = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageVoid.value * (currentPageVoid.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?limit=${perPageVoid.value}&offset=${to}&date_start=${startDateFilterVoid.value}&date_end=${endDateFilterVoid.value}&void=true`, { headers })
      .then(response => {
        totalPurchaseRequestsVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPurchaseRequestsAssetsFGS,
    fetchPurchaseRequestsAssetsFGSNA,
    fetchPurchaseRequestsFinishedAssetsFGS,
    fetchPurchaseRequestsSupplies,
    fetchPurchaseRequestsSuppliesNA,
    fetchPurchaseRequestsFinishedSupplies,
    fetchPurchaseRequestsHO,
    fetchPurchaseRequestsHONA,
    fetchPurchaseRequestsFinishedHO,
    fetchPurchaseRequestsVoid,
    perPageOptions,
    tableColumnsAssetsFGS,
    tableColumnsFinishedAssetsFGS,
    tableColumnsSupplies,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPageAssetsFGS,
    perPageFinishedAssetsFGS,
    totalPurchaseRequestsAssetsFGS,
    totalPurchaseRequestsFinishedAssetsFGS,
    currentPageAssetsFGS,
    currentPageFinishedAssetsFGS,
    perPageSupplies,
    perPageFinishedSupplies,
    totalPurchaseRequestsSupplies,
    totalPurchaseRequestsFinishedSupplies,
    currentPageSupplies,
    currentPageFinishedSupplies,
    perPageHO,
    perPageFinishedHO,
    perPageVoid,
    totalPurchaseRequestsHO,
    totalPurchaseRequestsFinishedHO,
    totalPurchaseRequestsVoid,
    currentPageHO,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryAssetsFGS,
    searchQueryFinishedAssetsFGS,
    sortByAssetsFGS,
    isSortDirDescAssetsFGS,
    startDateFilterAssetsFGS,
    endDateFilterAssetsFGS,
    startDateFilterFinishedAssetsFGS,
    endDateFilterFinishedAssetsFGS,
    searchQuerySupplies,
    searchQueryFinishedSupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    isSortDirDescHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    refPurchaseRequestListTableAssetsFGS,
    refPurchaseRequestListTableSupplies,
    refPurchaseRequestListTableHO,
    refPurchaseRequestListTableFinishedAssetsFGS,
    refPurchaseRequestListTableFinishedSupplies,
    refPurchaseRequestListTableFinishedHO,
    refPurchaseRequestListTableVoid,
    perPageAssetsFGSNA,
    perPageFinishedAssetsFGSNA,
    totalPurchaseRequestsAssetsFGSNA,
    currentPageAssetsFGSNA,
    searchQueryAssetsFGSNA,
    sortByAssetsFGSNA,
    isSortDirDescAssetsFGSNA,
    startDateFilterAssetsFGSNA,
    endDateFilterAssetsFGSNA,
    tableColumnsAssetsFGSNA,
    perPageSuppliesNA,
    totalPurchaseRequestsSuppliesNA,
    currentPageSuppliesNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    isSortDirDescSuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    tableColumnsSuppliesNA,
    perPageHONA,
    totalPurchaseRequestsHONA,
    currentPageHONA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    tableColumnsHONA,
    refPurchaseRequestListTableSuppliesNA,
    refPurchaseRequestListTableAssetsFGSNA,
    refPurchaseRequestListTableHONA,
    refetchDataAssetsFGS,
    refetchDataFinishedAssetsFGS,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchDataVoid,
  }
}
